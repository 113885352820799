<template>
  <div
    id="forecast-rule-modal"
    ref="forecastConfigModal"
    aria-hidden="true"
    class="modal fade"
    data-bs-backdrop="static"
    data-bs-keyboard="false"
    tabindex="-1"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            Edit rules
          </h5>
          <button
            aria-label="Close"
            class="btn-close"
            data-bs-dismiss="modal"
            type="button"
          />
        </div>
        <div class="modal-body">
          <form v-if="initialized">
            <TextInput
              v-model="config.approvalCutOffDate"
              :errors="v$.config.approvalCutOffDate.$errors"
              description="Forecast approval cut off date of the month"
              label="Forecast approval cut off date of the month"
              @blur="v$.config.approvalCutOffDate.$touch"
            />
          </form>
          <Spinner v-else />
          <div class="modal-footer d-flex justify-content-between">
            <button
              :disabled="isSaveButtonDisabled"
              aria-label="Close"
              class="btn btn-success"
              type="submit"
              @click="showSaveConfirmationModalHandler"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ConfirmationModal
    ref="forecastEditConfigConfirmationModal"
    :onCancelHandler="cancelEditHandler"
    :onConfirmHandler="saveChangesHandler"
  >
    <template #content>
      <div
        class="alert alert-warning"
        role="alert"
      >
        <h6>
          <i class="bi bi-exclamation-triangle" />
          The forecast configuration will be changed. Are you sure?
        </h6>
      </div>
    </template>
  </ConfirmationModal>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import ConfirmationModal from '../common/ConfirmationModal';
import TextInput from '../common/TextInput';
import Spinner from '../common/Spinner';
import { useVuelidate } from '@vuelidate/core';
import { Modal } from 'bootstrap';
import { integer, maxValue, minValue, required } from '@vuelidate/validators';

export default {
  components: {
    ConfirmationModal,
    TextInput,
    Spinner
  },
  props: ['viewType'],
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      modalViewType: null,
      confirmationModalSave: null,
      forecastEditConfigModal: null,
      initialized: false,
      config: {
        approvalCutOffDate: null,
      },
    };
  },
  validations() {
    return {
      config: {
        approvalCutOffDate: {
          required,
          integer,
          minValue: minValue(1),
          maxValue: maxValue(28)
        }
      },
    };
  },
  computed: {
    ...mapGetters({
      forecastConfig: 'forecast/forecastConfig'
    }),
    isSaveButtonDisabled() {
      return !this.initialized;
    },
  },
  async mounted() {
    this.forecastEditConfigModal = new Modal(this.$refs['forecastConfigModal']);
    this.confirmationModalSave = this.$refs['forecastEditConfigConfirmationModal'].confirmationModal;
    this.confirmationModalSave.hide();
  },
  methods: {
    ...mapActions({
      fetchConfig: 'forecast/fetchConfig',
      updateConfig: 'forecast/updateConfig'
    }),
    async initModal() {
      this.initialized = false;
      this.forecastEditConfigModal.show();
      await this.fetchConfig();
      const config = this.forecastConfig;
      this.updateItemData(config);
      this.initialized = true;
    },
    updateItemData(newValue) {
      this.config.approvalCutOffDate = newValue.approvalCutOffDate;
    },
    async showSaveConfirmationModalHandler() {
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) {
        this.$toast.error('Form should be valid.');
        return false;
      }
      this.forecastEditConfigModal.hide();
      this.confirmationModalSave.show();
    },
    async saveChangesHandler() {
      const dataToSave = {
        ...this.config
      };
      try {
        await this.updateConfig(dataToSave);
        this.$toast.success('Config successfully updated');
        await this.fetchConfig();
      } catch (e) {
        this.$toast.error('Failed to save changes.');
      }
    },
    cancelEditHandler() {
      this.confirmationModalSave.hide();
    },
  }
};
</script>
