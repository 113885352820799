<template>
  <div class="d-flex flex-column justify-content-center mt-5">
    <div
      v-if="forecastConfigVisible"
      class="row d-flex justify-content-center pb-3"
    >
      <div class="col col-3">
        <div class="card">
          <div class="card-header d-flex justify-content-between">
            <h5 class="mt-1 mb-0">
              Forecast configuration
            </h5>
            <button
              v-if="editForecastConfigBtnVisible"
              class="btn btn-sm btn-primary"
              @click="editConfigClickHandler"
            >
              Edit
            </button>
          </div>
          <div class="card-body w-100">
            <div class="profile-container">
              <TextInput
                v-model="forecastConfig.approvalCutOffDate"
                class="p-2"
                label="Forecast approval cut off date of the month"
                readonly
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center">
      <div
        class="card"
        style="width: 1200px"
      >
        <div class="card-header d-flex justify-content-between">
          <h5 class="mt-1 mb-0">
            Forecast users
          </h5>
        </div>
        <div
          class="card-body p-0"
          style="height: 52em"
        >
          <ag-grid-vue
            :columnDefs="columnForecastUsersDefs"
            :defaultColDef="defaultForecastUsersColDef"
            :rowData="forecastUsers"
            :pagination="true"
            :paginationAutoPageSize="true"
            class="ag-theme-alpine w-100 h-100 slaEditModal"
          />
        </div>
      </div>
    </div>
  </div>
  <ForecastUserEditModal
    ref="forecastUserModal"
    :viewType="modalViewType"
  />
  <ForecastEditConfigModal
    ref="forecastConfigModal"
    :viewType="modalViewType"
  />
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { AgGridVue } from 'ag-grid-vue3';
import AgGreedButtonCellRenderer from '@/components/common/AgGreedButtonCellRenderer';
import { modalViewTypes, userPrivileges } from '@/components/constants';
import { booleanIconCellRenderer } from '@/lib/agGridCellRenderers';
import ForecastUserEditModal from '@/components/Forecast/ForecastUserEditModal.vue';
import TextInput from '@/components/common/TextInput.vue';
import ForecastEditConfigModal from '@/components/Forecast/ForecastEditConfigModal.vue';
import { hasPrivilege } from '@/service/userAccess';

export default {
  components: {
    ForecastEditConfigModal,
    TextInput,
    ForecastUserEditModal,
    AgGridVue,
    // eslint-disable-next-line vue/no-unused-components
    AgGreedButtonCellRenderer
  },
  data() {
    return {
      modalViewType: null,
      loading: false
    };
  },
  computed: {
    ...mapGetters({
      actualBrands: 'forecast/companyItems',
      forecastUsers: 'forecast/forecastUsers',
      forecastConfig: 'forecast/forecastConfig'
    }),
    forecastConfigVisible() {
      return hasPrivilege(userPrivileges.getForecastConfig);
    },
    editForecastConfigBtnVisible() {
      return hasPrivilege(userPrivileges.updateForecastConfig);
    },
    defaultForecastUsersColDef() {
      return {
        resizable: true,
        initialWidth: 200,
        wrapHeaderText: true,
        autoHeaderHeight: true
      };
    },
    columnForecastUsersDefs() {
      return [
        {
          headerName: '',
          field: 'userId',
          cellRenderer: 'AgGreedButtonCellRenderer',
          cellRendererParams: {
            clicked: (field) => {
              this.rowRecipientClickHandler(field);
            }
          },
          width: 80
        },
        {
          headerName: 'User name',
          field: 'userName',
          sortable: true,
          width: 250
        },
        {
          headerName: 'Email',
          field: 'email',
          sortable: true,
          width: 340
        },
        {
          headerName: 'Receive Lack of Forecast alerts',
          field: 'receiveLackOfForecastAlert',
          cellRenderer: booleanIconCellRenderer(),
          width: 130
        },
        {
          field: 'receiveNewForecastAlert',
          headerName: 'Receive New Forecast alerts',
          cellRenderer: booleanIconCellRenderer(),
          width: 130
        },
        {
          field: 'receiveForecastDecision',
          headerName: 'Receive Forecast decisions',
          cellRenderer: booleanIconCellRenderer(),
          width: 130
        },
        {
          field: 'receiveMismatchAlert',
          headerName: 'Receive Forecast Mismatch alerts',
          cellRenderer: booleanIconCellRenderer(),
          width: 130
        },
      ];
    },
  },
  async mounted() {
    this.loading = true;
    await this.fetchActualBrands();
    await this.fetchForecastUsers();
    if (this.forecastConfigVisible) {
      await this.fetchForecastConfig();
    }
    this.loading = false;
  },
  methods: {
    ...mapActions({
      fetchActualBrands: 'forecast/fetchCompanyItems',
      fetchForecastUsers: 'forecast/fetchForecastUsers',
      fetchForecastConfig: 'forecast/fetchConfig'
    }),
    rowRecipientClickHandler(id) {
      this.modalViewType = modalViewTypes.edit;
      this.$refs.forecastUserModal.initModal(id);
    },
    editConfigClickHandler() {
      this.modalViewType = modalViewTypes.edit;
      this.$refs.forecastConfigModal.initModal();
    }
  }
};
</script>

<style scoped>

</style>
